import { useDispatch, useSelector } from 'react-redux';
import { languageOptions } from 'common_constants/translation';
import { Select } from 'antd';

import reactNativeService from '../../tools/reactNativeService';
import { setLanguage } from '../../store/commonReducer';

import GB from 'country-flag-icons/react/3x2/GB';
import UA from 'country-flag-icons/react/3x2/UA';
import DE from 'country-flag-icons/react/3x2/DE';
import FR from 'country-flag-icons/react/3x2/FR';
import ES from 'country-flag-icons/react/3x2/ES';
import CZ from 'country-flag-icons/react/3x2/CZ';
import RU from './RU.jsx';

import './LanguageSwitcher.scss';

const { Option } = Select;

const LanguageSwitcher = () => {
  const dispatch = useDispatch();
  const currentLang = useSelector((state) => state.common.language);

  const handleLanguageChange = (value) => {
    dispatch(setLanguage(value));
    reactNativeService.sendMessageToWebview({ language: value });
    localStorage.setItem('language', value);
  };

  const flagComponents = {
    ua: <UA />,
    en: <GB />,
    de: <DE />,
    fr: <FR />,
    es: <ES />,
    cs: <CZ />,
    ru: <RU />,
  };

  const optionsWithFlags = languageOptions
    .map((option) => ({
      ...option,
      label: (
        <div className="language-option">
          {flagComponents[option.value]} {option.label}
        </div>
      ),
    }))
    .sort((a, b) => (a.value === currentLang ? -1 : 1));

  return (
    <Select className="language-switcher" value={currentLang} onChange={handleLanguageChange} popupClassName="language-dropdown">
      {optionsWithFlags.map((option) => (
        <Option className="language-switcher-option" key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default LanguageSwitcher;
