import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MOB_RESOLUTION } from 'common_constants/ui';
import { setMob } from '../store/uiReducer';
import reactNativeService from './reactNativeService';

const useControllView = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      dispatch(setMob(window.innerWidth < MOB_RESOLUTION));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
};

const useAuthData = () => {
  const userAuth = useSelector((state) => state.common.userAuth);

  useEffect(() => {
    const data = {
      ...userAuth,
      token: localStorage.getItem('partnerToken'),
    };
    reactNativeService.sendMessageToWebview({ type: 'userAuthData', data: data });
  }, [userAuth?._id]);
};
const usePagination = ({ totalsItemsInit = 0, currentPageInit = 1, pageSizeInit = 10 }) => {
  const [totalItems, setTotalItems] = useState(totalsItemsInit);
  const [currentPage, setCurrentPage] = useState(currentPageInit);
  const [pageSize, setPageSize] = useState(pageSizeInit);

  const totalPages = Math.ceil(totalItems / pageSize);

  const nextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToPage = (pageNumber) => {
    const page = Math.max(1, Math.min(pageNumber, totalPages));
    setCurrentPage(page);
  };
  const resetPagination = () => {
    setCurrentPage(1);
  };

  return {
    currentPage,
    pageSize,
    totalPages,
    totalItems,
    setTotalItems,
    setPageSize,
    setCurrentPage,
    nextPage,
    prevPage,
    goToPage,
    resetPagination,
  };
};

const useDebounce = (value, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState('');
  const timerRef = useRef();

  useEffect(() => {
    timerRef.current = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
};
export { useControllView, useAuthData, usePagination, useDebounce };
