import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const UserAvatar = ({ user, style, size, className, shape }) => {
  const imageName = user?.upa;

  // const defaultURL = 'https://th.bing.com/th/id/R.742272f24d8a63716316916334873f53?rik=ucSMX3z0NapV8A&pid=ImgRaw&r=0';
  const unknownURL = 'https://th.bing.com/th/id/OIP.NmdbjgnCEbGC6o4A43nhJgHaHs?pid=ImgDet&w=195&h=202&c=7&dpr=1.5';
  const userAvatarURL = imageName ? process.env.REACT_APP_API + `/avatars/${imageName}_200.jpeg` : null;

  return <Avatar shape={shape} style={style} className={className} size={size} icon={<UserOutlined />} src={user ? userAvatarURL : unknownURL} />;
};

export default UserAvatar;
