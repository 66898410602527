import { useSelector } from 'react-redux';
import { WORK_DAYS, WORK_TIME } from 'common_constants/business';
import { Button, Collapse } from 'antd';

const { Panel } = Collapse;

const ScheduleItem = ({ item, setSchedule, schedule }) => {
  const lang = useSelector((state) => state.common.language);

  const isTimeInRange = (time) => {
    if (!item.startT || !item.endT) return false;
    const startIndex = WORK_TIME.indexOf(item.startT);
    const endIndex = WORK_TIME.indexOf(item.endT);
    const currentIndex = WORK_TIME.indexOf(time);
    return currentIndex >= startIndex && currentIndex <= endIndex;
  };

  const updateSchedule = (startT, endT) => {
    const updatedSchedule = schedule.map((i) => {
      if (i.key === item.key) {
        return { ...i, startT, endT };
      }
      return i;
    });
    setSchedule(updatedSchedule);
  };

  const handleButtonClick = (time) => {
    if (!item.startT) {
      updateSchedule(time, item.endT);
    } else if (!item.endT && time > item.startT) {
      updateSchedule(item.startT, time);
    } else if (!item.endT && time < item.startT) {
      updateSchedule(time, item.endT);
    } else if (time < item.startT) {
      updateSchedule(time, item.endT);
    } else if (time > item.endT) {
      updateSchedule(item.startT, time);
    } else {
      updateSchedule(null, null);
    }
  };

  const currentDay = WORK_DAYS.find((i) => i.key === item.key);

  return (
    <Collapse bordered={false} style={{ marginBottom: 16 }}>
      <Panel
        header={
          <span style={{ marginRight: '8px' }}>
            {currentDay.label[lang]}:{' '}
            {item.startT && item.endT ? (
              <>
                {item.startT} - {item.endT}
              </>
            ) : (
              'Вихідний'
            )}
          </span>
        }
        key="1"
      >
        <div style={{ display: 'flex', justifyContent: 'center', gap: '8px', flexWrap: 'wrap', marginLeft: 'auto', marginRight: 'auto' }}>
          {WORK_TIME.map((time) => {
            const isSelected = time === item.startT || time === item.endT;
            const isInRange = isTimeInRange(time);

            return (
              <Button
                key={time}
                type={isSelected ? 'primary' : 'default'}
                style={{
                  backgroundColor: isSelected || isInRange ? '#1677ff' : '',
                  color: isSelected || isInRange ? 'white' : '',
                  width: '80px',
                }}
                onClick={() => handleButtonClick(time)}
              >
                {time}
              </Button>
            );
          })}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}>
          <Button type="default" onClick={() => updateSchedule(null, null)} style={{ backgroundColor: '#ff2e2e', color: 'white' }}>
            Очистити час
          </Button>
        </div>
      </Panel>
    </Collapse>
  );
};

export default ScheduleItem;
