import { Spin } from 'antd';

const MainLoader = ({ show }) => (
  <div
    style={{
      width: '100%',
      height: '100%',
      position: 'fixed',
      display: show ? 'flex' : 'none',
      alignItems: 'center',
      top: 0,
      left: 0,
      zIndex: 9999,
      background: 'rgba(225, 225, 225, 0.6)',
    }}
  >
    <div
      style={{
        margin: 'auto',
        width: '65px',
      }}
    >
      <Spin spinning={true} tip="Зачекайте" size="large" />
    </div>
  </div>
);

export default MainLoader;
