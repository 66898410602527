import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Typography, Spin, Modal } from 'antd';
import dayjs from 'dayjs';
import { Box } from 'common_components';
import { translation } from 'common_constants/translation';

import { error, request } from '../../tools';
import { setModal } from '../../store/commonReducer';

const { Text } = Typography;

const HistoryHotlines = () => {
  const dispatch = useDispatch();
  const { phone, countryPhCode } = useSelector((state) => state.common.modal.data);
  const lang = useSelector((state) => state.common.language);

  const [hotlines, setHotlines] = useState([]);
  const [loading, setLoading] = useState(false);

  const onCancel = () => dispatch(setModal());

  const getHotlineResult = () => {
    request.post(
      '/partnersHotlines/getByPhone',
      { phone: phone, countryPhCode: countryPhCode },
      (res) => {
        const sorted =
          res.data?.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
          }) || [];
        setHotlines(sorted);
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    setLoading(true);
    getHotlineResult();
  }, []);

  return (
    <Spin tip="Завантаження" spinning={loading}>
      <Modal className="library-info-list" open title={'Історія консультацій'} onCancel={onCancel} footer={null}>
        <Box>
          {hotlines.map((item) => (
            <Card size="small" key={item._id}>
              <Text strong>{dayjs(item.date).format('DD.MM.YYYY')}</Text>
              <br />
              <Text strong>{item.name}</Text>
              <br />
              <Text strong>
                +{item.countryPhCode} {item.phone}
              </Text>
              <br />
              <span>
                <Text strong>{translation.status[lang]}:</Text>&nbsp;
                <Text type={item.s === '1' ? 'success' : 'danger'}>
                  {item.s === '1' ? translation.okConsultation[lang] : translation.missedConsultation[lang]}
                </Text>
              </span>
              <br />
              <Text strong>{translation.price[lang]}:</Text> {item.cost}
              <br />
              <Text strong>{translation.comment[lang]}:</Text> {item.comment !== '' ? item.comment : '-'}
            </Card>
          ))}
        </Box>
      </Modal>
    </Spin>
  );
};

export default HistoryHotlines;
