import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spin, Input, Button, Alert, Calendar, Collapse, Popover } from 'antd';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { COUNTRY_PHONE_CODES, WORK_TIME } from 'common_constants/business';
import { translation } from 'common_constants/translation';

import { setModal } from '../../store/commonReducer';
import CountryPhCode from '../../components/CountryPhCode/CountryPhCode';
import { request, success, error } from '../../tools';

dayjs.extend(isBetween);

const { Panel } = Collapse;

const AddPartnersHotline = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.common.modal);
  const userAuth = useSelector((state) => state.common.userAuth);
  const currentAuthUserPersonalDate = useSelector((state) => state.common.currentAuthUserPersonalDate);
  const lang = useSelector((state) => state.common.lang);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(data?.name || '');
  const [countryPhCode, setCountryPhCode] = useState(data?.countryPhCode || COUNTRY_PHONE_CODES.UA);
  const [phone, setPhone] = useState(data?.phone || '');
  const [cost, setCost] = useState(data?.cost || '');
  const [date, setDate] = useState(data ? dayjs(data.date) : dayjs());
  const [startT, setStartT] = useState(data?.startT || null);
  const [endT, setEndT] = useState(data?.endT || null);
  const [comment, setComment] = useState(data?.comment || '');
  const [errorText, setErrorText] = useState('');
  const [blockedTimes, setBlockedTimes] = useState([]);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [foundName, setFoundName] = useState('');

  const handleSubmit = async () => {
    if (!endT) {
      setErrorText(translation.chooseEndTime[lang]);
      return;
    }

    if (!name || !phone || !date || !startT || !endT || !cost) {
      setErrorText(translation.fillAll[lang]);
      return;
    }

    setLoading(true);
    const holineData = {
      fil: userAuth.fil,
      name,
      countryPhCode,
      phone,
      cost: Number(cost),
      date: date.format('YYYY-MM-DD'),
      startT,
      endT,
      comment,
    };

    const url = data?._id ? `/partnersHotlines/update/${data._id}` : '/partnersHotlines/addNewHotline';

    await request.post(
      url,
      holineData,
      ({ status }) => {
        success('', data?._id ? translation.successfullyCreated[lang] : translation.successfullyUpdated[lang]);
        dispatch(setModal(false));
        setLoading(false);
        status && data?.onAddSuccess?.(date);
      },
      () => {
        error('', data?._id ? translation.errorWhileCreating[lang] : translation.errorWhileUpdating[lang]);
        setLoading(false);
      },
    );
  };

  const fetchBlockedTimes = async () => {
    setLoading(true);

    const holineData = {
      date: date.format('YYYY-MM-DD'),
    };

    await request.post('/partnersHotlines/checkAvailability', holineData, ({ status, blockedTimes }) => {
      setLoading(false);
      if (status) {
        setBlockedTimes(blockedTimes);
      }
    });
  };

  const getClientsName = () => {
    const body = { phone, countryPhCode };
    if (!phone) return;

    request.post(
      '/partnersHotlines/getNameByPhone',
      body,
      (res) => {
        if (res.data?.name) {
          setFoundName(res.data.name);
          setIsPopoverVisible(true); // Show popover when name is found
        } else {
          setFoundName(''); // Clear found name if not found
        }
      },
      error,
    );
  };

  const handleConfirmName = () => {
    setName(foundName);
    setIsPopoverVisible(false);
    setFoundName('');
  };

  const handleCancelPopover = () => {
    setIsPopoverVisible(false);
    setFoundName('');
  };

  const clearTime = () => {
    setStartT(null);
    setEndT(null);
  };

  const isTimeInRange = (time) => {
    if (startT && endT) {
      return time > startT && time < endT;
    }
    return false;
  };

  const isTimePassed = (time) => {
    const selectedDateTime = dayjs(`${date.format('YYYY-MM-DD')} ${time}`);
    return selectedDateTime.isBefore(dayjs());
  };

  const isWorkingHours = (time) => {
    const dayOfWeek = dayjs(date).day() === 0 ? 7 : dayjs(date).day();

    const scheduleItem = currentAuthUserPersonalDate?.schedule.find((item) => item.key === dayOfWeek);

    if (!scheduleItem || !scheduleItem.startT || !scheduleItem.endT) {
      return false;
    }

    return time >= scheduleItem.startT && time <= scheduleItem.endT;
  };

  const areTimesDisabled = () => {
    return date.isBefore(dayjs(), 'day');
  };

  const disabledDate = (current) => {
    if (current.isBefore(dayjs(), 'day')) {
      return true;
    }
    const dayOfWeek = current.day();
    const scheduleItem = currentAuthUserPersonalDate?.schedule.find((item) => item.key === (dayOfWeek === 0 ? 7 : dayOfWeek));
    return !scheduleItem || !scheduleItem.startT || !scheduleItem.endT;
  };

  useEffect(() => {
    fetchBlockedTimes(date);
  }, [date]);

  return (
    <Modal
      title={data?._id ? translation.editHotline[lang] : translation.addHotline[lang]}
      className="hotline-que-modal"
      open
      onCancel={() => dispatch(setModal())}
      footer={null}
    >
      <Spin tip={translation.loader[lang]} spinning={loading}>
        <span>{translation.phone[lang]}:</span>
        <div style={{ display: 'flex', marginBottom: 16 }}>
          <CountryPhCode value={countryPhCode} onChange={setCountryPhCode} /> &nbsp;
          <Input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            onBlur={getClientsName}
            maxLength={10}
            placeholder={translation.confirmChangePhoneRules4[lang]}
          />
        </div>

        <span>{translation.clientProfileFullName[lang]}:</span>
        <Popover
          content={
            <div style={{ width: '80%' }}>
              <p>
                {translation.foundUser[lang]}: <span style={{ fontWeight: 'bold' }}>{foundName}</span>
              </p>
              <Button type="primary" onClick={handleConfirmName}>
                {translation.confirm[lang]}
              </Button>
              <Button type="link" onClick={handleCancelPopover}>
                {translation.cancel[lang]}
              </Button>
            </div>
          }
          title={translation.approvement[lang]}
          trigger="click"
          visible={isPopoverVisible}
        >
          <Input
            placeholder={translation.addClientNameForInsurancePlaceholder[lang]}
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ marginBottom: 16 }}
          />
        </Popover>

        <span>{translation.cost[lang]}:</span>
        <Input
          placeholder={translation.enterPrice[lang]}
          value={cost}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*$/.test(value)) setCost(value);
          }}
          style={{ marginBottom: 16 }}
        />

        <Collapse bordered={false} style={{ marginBottom: 16 }}>
          <Panel header={translation.enterDate[lang]} key="1">
            <Calendar
              value={date}
              onSelect={(dateValue) => {
                setDate(dayjs(dateValue));
              }}
              disabledDate={disabledDate}
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: '4px',
              }}
              fullscreen={false}
            />
          </Panel>
        </Collapse>

        <Collapse bordered={false} style={{ marginBottom: 16 }}>
          <Panel header="Обрати час" key="2">
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', justifyContent: 'center' }}>
              {WORK_TIME.map((time) => {
                const isPast = isTimePassed(time);
                const isWorking = isWorkingHours(time);
                const isSelected = startT === time || endT === time;
                const isInRange = isTimeInRange(time);
                const isEditing = !!data?._id;
                const selectedTime = dayjs(time, 'HH:mm');
                const isBlocked = blockedTimes.some((slot) => {
                  const start = dayjs(slot.startT, 'HH:mm');
                  const end = dayjs(slot.endT, 'HH:mm');

                  return selectedTime.isBetween(start, end, null, '[]');
                });

                const editingCondition = isEditing ? isPast || (areTimesDisabled() && !isInRange) : isPast || (areTimesDisabled() && isInRange);

                return (
                  <Button
                    key={time}
                    type={isSelected ? 'primary' : 'default'}
                    disabled={isBlocked || editingCondition || !isWorking}
                    style={{
                      width: '80px',
                      height: '40px',
                      backgroundColor: isEditing && isInRange ? '#1677ff' : isEditing && isSelected ? '#1677ff' : isInRange ? '#1677ff' : '',
                      color: (isSelected && isEditing) || isInRange ? 'white' : '',
                    }}
                    onClick={() => {
                      if (!startT) {
                        setStartT(time);
                      } else if (!endT && time > startT) {
                        setEndT(time);
                      } else {
                        setStartT(time);
                        setEndT(null);
                      }
                    }}
                  >
                    {time}
                  </Button>
                );
              })}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}>
              <Button type="default" onClick={clearTime} style={{ backgroundColor: '#ff2e2e', color: 'white' }}>
                Очистити час
              </Button>
            </div>
          </Panel>
        </Collapse>

        <Input.TextArea placeholder="Коментар" value={comment} onChange={(e) => setComment(e.target.value)} style={{ marginBottom: 16 }} rows={4} />

        <hr />
        <hr />
        <br />

        {errorText && <Alert message={errorText} type="error" showIcon closable onClose={() => setErrorText('')} />}

        <div style={{ padding: '8px 16px', backgroundColor: '#fafafa', borderRadius: '4px', marginBottom: '16px' }}>
          <h3>Перевір перед збереженням:</h3>
          <span>
            <strong>Телефон:</strong> +{countryPhCode} {phone || '-'}
          </span>
          <br />
          <span>
            <strong>ПІБ клієнта:</strong> {name || '-'}
          </span>
          <br />
          <span>
            <strong>Вартість:</strong> {cost || '-'}
          </span>
          <br />
          <span>
            <strong>Дата:</strong> {date.format('DD.MM.YYYY')}
          </span>
          <br />
          <span>
            <strong>Час:</strong> {startT ? `${startT} - ${endT}` : '-'}
          </span>
          <br />
          <span>
            <strong>Коментар:</strong> {comment || '-'}
          </span>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button type="default" onClick={() => dispatch(setModal())} style={{ width: '48%' }}>
            Відмінити
          </Button>
          <Button type="primary" onClick={handleSubmit} style={{ width: '48%' }}>
            {data?._id ? 'Оновити' : 'Зберегти'}
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

export default AddPartnersHotline;
