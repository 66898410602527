import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload, Typography, Row, Col } from 'antd';

import { request } from '../../tools';
import { editUser } from '../../store/commonReducer';
import UserAvatar from '../../components/UserAvatar';
import CurrentUserPersonalInfo from '../../components/CurrentUserPersonalInfo/CurrentUserPersonalInfo';

const { Title } = Typography;

const Profile = () => {
  const dispatch = useDispatch();

  const userAuth = useSelector((state) => state.common.userAuth),
    users = useSelector((state) => state.common.users),
    user = users?.[userAuth?._id];

  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = () => {
    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append('file', file);
      formData.append('_id', userAuth._id);
      if (user?.upa) {
        formData.append('prevAvatarId', user.upa);
      }
    });
    setUploading(true);
    request.post(
      '/auth/partnerProfileEdit',
      formData,
      (res) => {
        setFileList([]);
        message.success('Фото успішно завантажено');
        setUploading(false);
        const newUser = {
          [userAuth._id]: {
            ...userAuth,
            upa: res.data.fileId,
          },
        };
        dispatch(editUser(newUser));
      },
      () => {
        message.error('Сталася помилка');
        setUploading(false);
      },
    );
  };

  const props = {
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      const isJpg = file.type === 'image/jpeg';

      if (!isJpg) {
        message.error('Можливо завантажити фото тільки в JPG форматі!');
        setFileList([]);
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Розмір файлу повинен бути менше 2 мегабайт!');
        setFileList([]);
      }
      if (!isJpg || !isLt2M) {
        return false;
      }
      setFileList([file]);
      return false;
    },
    fileList,
  };

  return (
    <div className="currentUserPersonalInfo" style={{ padding: '40px 24px' }}>
      <Row gutter={24} justify="center" align="middle">
        <Col xs={24} sm={8} md={6}>
          <div className="avatar-container" style={{ textAlign: 'center' }}>
            <UserAvatar user={user} size={140} style={{ width: 140, height: 140, borderRadius: '50%', border: '2px solid #f0f0f0' }} />
            <Title level={4} style={{ marginTop: 10 }}>
              {user?.name}
            </Title>
          </div>
        </Col>

        <Col xs={24} sm={16} md={12}>
          <div className="upload-section" style={{ marginTop: 24, textAlign: 'center' }}>
            <Upload {...props}>
              <Button icon={<UploadOutlined />} size="large" style={{ padding: '10px 20px', fontSize: 16 }}>
                Оберіть фото
              </Button>
            </Upload>
            <div style={{ marginTop: 16 }}>
              <Button
                type="primary"
                onClick={handleUpload}
                disabled={fileList.length === 0}
                loading={uploading}
                size="large"
                style={{
                  width: '100%',
                  maxWidth: '300px',
                  marginTop: 16,
                  fontSize: 16,
                  padding: '10px 20px',
                }}
              >
                {uploading ? 'Завантаження...' : 'Завантажити'}
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <div style={{ marginTop: 40 }}>
        <CurrentUserPersonalInfo />
      </div>
    </div>
  );
};

export default Profile;
