import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { OPEN_ROUTES, ROUTES } from 'common_constants/routes';
import { setMainLoader } from '../../store/uiReducer';
import { prepareUsers, request } from '../../tools';
import { setActualization, setCurrentAuthUserPersonalDate, setUsers, setAppPrepared } from '../../store/commonReducer';

export default function AuthControl() {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);

  const { pathname } = useLocation();
  const history = useHistory();

  const openRoute = OPEN_ROUTES.includes(pathname);
  const isHomePage = pathname === ROUTES.HOME;

  const logOut = () => {
    window.localStorage.removeItem('partnerToken');
    window.location.reload();
  };

  const getInitialData = () => {
    dispatch(setMainLoader(true));
    request.post('/universal/partnerInitial', {}, (res) => {
      const _users = prepareUsers(res.users);
      dispatch(setUsers(_users));
      dispatch(setCurrentAuthUserPersonalDate(res.userPersonalData || {}));
      dispatch(setActualization(res.actualization || {}));
      dispatch(setAppPrepared(true));
      dispatch(setMainLoader(false));
    });
  };

  const checkToken = () => {
    if (!userAuth) return;

    request.post(
      '/auth/checkPartnerToken',
      {},
      () => {
        getInitialData();
        dispatch(setMainLoader(false));
      },
      logOut,
    );
  };

  useEffect(() => {
    if (userAuth) checkToken();
  }, []);

  // Redirect from Login pages
  if (openRoute) return null;

  // Redirect to Home pages
  if (!openRoute && !userAuth) {
    history.push(ROUTES.AUTH);
  }

  if (isHomePage) {
    history.push(ROUTES.LIST);
  }

  return null;
}
