import React from 'react';
import { useSelector } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { translation } from 'common_constants/translation';

const { Text } = Typography;

const ClientInfoRow = (props) => {
  const { data } = props;

  const lang = useSelector((state) => state.common.language);

  return (
    <span style={{ margin: 0, fontSize: '14px', color: '#777' }}>
      <UserOutlined style={{ fontSize: '18px', color: '#1890ff' }} /> &nbsp;
      <Text strong>{translation.client[lang]}:</Text>&nbsp;
      {!data.client[0] ? (
        <span className="error">{translation.error[lang]}</span>
      ) : (
        <>
          +{data.client[0]?.countryPhCode}&nbsp;
          {data.client[0]?.ph} &nbsp;
          <br />
          {data.client[0]?.n} &nbsp;
        </>
      )}
    </span>
  );
};

export default ClientInfoRow;
