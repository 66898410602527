import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, Form, Input, Button, Typography } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { ROUTES } from 'common_constants/routes';
import { translation } from 'common_constants/translation';

import { setActualization, setCurrentAuthUserPersonalDate, setUserAuth, setUsers, setAppPrepared } from '../../store/commonReducer';
import { setMainLoader } from '../../store/uiReducer';
import { request, getTokenData, error, prepareUsers } from '../../tools';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';

import './Auth.scss';

const { Title, Text } = Typography;

const Auth = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.common.language);

  const onFinish = async (data) => {
    loginRequest({
      email: data.email,
      password: data.password,
    });
  };

  const loginRequest = (data) => {
    request.post('/auth/partnerLogin', data, loginReqCallback, error);
  };

  const loginReqCallback = (res) => {
    if (res?.userIsFired) {
      error('', translation.accessDenied[lang]);
      return;
    }
    window.localStorage.setItem('partnerToken', res.partnerToken);
    dispatch(setUserAuth(getTokenData(res.partnerToken)));
    getInitialData();
    history.push(ROUTES.LIST);
  };

  const getInitialData = () => {
    dispatch(setMainLoader(true));
    request.post('/universal/partnerInitial', {}, (res) => {
      const _users = prepareUsers(res.users);
      dispatch(setUsers(_users));
      dispatch(setCurrentAuthUserPersonalDate(res.userPersonalData || {}));
      dispatch(setActualization(res.actualization || {}));
      dispatch(setAppPrepared(true));
      dispatch(setMainLoader(false));
    });
  };

  return (
    <div className="auth_page">
      <Card className="auth_card">
        <div className="auth_header">
          <Title level={3}>{translation.welcome[lang]}</Title>
          <Text type="secondary">{translation.welcomeText[lang]}</Text>
        </div>
        <LanguageSwitcher />

        <Form name="login" layout="vertical" initialValues={{ remember: true }} onFinish={onFinish} className="auth_form" autoComplete="off">
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: translation.clientCPEmailMessage2[lang] },
              { type: 'email', message: translation.clientCPEmailMessage1[lang] },
            ]}
          >
            <Input prefix={<MailOutlined />} placeholder={translation.clientCPEmailMessage2[lang]} />
          </Form.Item>

          <Form.Item label={translation.password[lang]} name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
            <Input.Password placeholder={translation.clientCPPasswordMessage1[lang]} />
          </Form.Item>

          {/* <Form.Item name="remember" valuePropName="checked">
            <Checkbox>{translation.rememberMe[lang]}</Checkbox>
          </Form.Item> */}

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              {translation.enter[lang]}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Auth;
