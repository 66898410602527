import { useState } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined, LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { Checkbox, Typography, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';
import { error, request, success } from '../../../tools';
import { setContractsList } from '../../../store/commonReducer';

import './ContractServices.scss';

const ITEMS_PER_PAGE = 6;

const ContractServices = ({ data }) => {
  const dispatch = useDispatch();
  const { Text } = Typography;

  const [service, setService] = useState('');
  const [price, setPrice] = useState('');
  const [cashPayment, setCashPayment] = useState(false);
  const [currentServices, setCurrentServices] = useState(data.services || []);
  const [currentPage, setCurrentPage] = useState(1);

  const contractsList = useSelector((state) => state.common.contracts);
  const lang = useSelector((state) => state.common.language);

  const addService = async () => {
    const formattedDate = new Date().toISOString();

    const newService = {
      date: formattedDate,
      service,
      cashPayment,
      price,
    };

    const updatedServices = [newService, ...currentServices];
    const contract = { ...data, services: updatedServices };
    await request.post(
      '/partnersContracts/addPartnerContract',
      { ...contract },
      (res) => {
        const updatedContractsList = contractsList.map((item) => (item._id === res.data._id ? res.data : item));

        dispatch(setContractsList(updatedContractsList));
        setCurrentServices(res.data.services);
        success();
      },
      error,
    );

    setPrice('');
    setService('');
    setCashPayment(false);
  };

  const paginatedServices = currentServices.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  const totalPages = Math.ceil(currentServices.length / ITEMS_PER_PAGE);

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="partners-services">
      <div className="add-service-card">
        <div className="form-group">
          <input
            value={service}
            className="form-input _title"
            onChange={(e) => setService(e.target.value)}
            placeholder={translation.describeService[lang]}
          />
        </div>

        <div className="form-group">
          <input
            type="number"
            onWheel={(e) => e.target.blur()}
            value={price}
            className="form-input _title"
            onChange={(e) => setPrice(e.target.value)}
            placeholder={translation.sum[lang]}
          />
        </div>

        <div className="form-chekbox">
          <span>{translation.paidWythCash[lang]}:</span>
          <Checkbox checked={cashPayment} onChange={() => setCashPayment(!cashPayment)} />
        </div>

        <div className="add-new-task">
          <button className={`${!service || !price ? 'disabled' : 'save-task'}`} disabled={!service || !price} onClick={addService}>
            <PlusOutlined /> <p>{translation.addService[lang]}</p>
          </button>
        </div>
      </div>

      {currentServices.length !== 0 && (
        <>
          <div className="partners-services-grid">
            {paginatedServices.map((item, index) => {
              const date = new Date(item?.date).toLocaleString('UK-ua', {
                day: '2-digit',
                month: 'numeric',
                year: 'numeric',
              });
              return (
                <div key={index} className="service-card">
                  <p>
                    <Text strong>{translation.date[lang]}:</Text>&nbsp;
                    <Text>{date}</Text>
                  </p>
                  <p>
                    <Text strong>{translation.service[lang]}:</Text>&nbsp;
                    <Text>{item?.service}</Text>
                  </p>
                  <p>
                    <Text strong>{translation.sum[lang]}:</Text>&nbsp;
                    <Text>{item?.price}</Text>
                  </p>

                  <p>
                    <Text strong>{translation.paidWythCash[lang]}:</Text>&nbsp;
                    {item?.cashPayment ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
                  </p>
                </div>
              );
            })}
          </div>

          {totalPages > 1 && (
            <div className="pagination-controls">
              <Button onClick={prevPage} disabled={currentPage === 1}>
                <LeftOutlined />
              </Button>
              <span>
                {translation.page[lang]} {currentPage} {translation.of[lang]} {totalPages}
              </span>
              <Button onClick={nextPage} disabled={currentPage === totalPages}>
                <RightOutlined />
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ContractServices;
