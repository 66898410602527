import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Row, Col } from 'antd';
import { CalendarOutlined, MailOutlined, UnorderedListOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { translation } from 'common_constants/translation';
dayjs.extend(utc);

const { Text } = Typography;

const ContractInfoTextPart = ({ data }) => {
  const lang = useSelector((state) => state.common.language);

  return (
    <Row gutter={[16, 16]} style={{ maxWidth: '600px', margin: '0 auto' }}>
      <Col span={24}>
        <Row align="middle">
          <Col style={{ display: 'flex' }}>
            <CalendarOutlined style={{ fontSize: '18px', color: '#1890ff', marginRight: 4 }} />
            <Text strong>{translation.clientCDate[lang]}:</Text>&nbsp;
          </Col>
          <Col>
            <Text style={{ margin: 0, fontSize: '14px', color: '#777' }}>{dayjs(data.sd)?.format('DD-MM-YYYY') ?? '-'}</Text>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row align="middle">
          <Col style={{ display: 'flex' }}>
            <MailOutlined style={{ fontSize: '18px', color: '#1890ff', marginRight: 4 }} /> <Text strong>Email:</Text>&nbsp;
          </Col>
          <Col>
            <Text style={{ margin: 0, fontSize: '14px', color: '#777' }}>{data.email ?? '-'}</Text>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row align="middle">
          <Col style={{ display: 'flex' }}>
            <UnorderedListOutlined style={{ fontSize: '18px', color: '#1890ff', marginRight: 4 }} /> <Text strong>{translation.note[lang]}:</Text>
            &nbsp;
          </Col>
          <Col>
            <Text style={{ margin: 0, fontSize: '14px', color: '#777' }}>{data.cd ?? '-'} </Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ContractInfoTextPart;
